<template>
  <b-container>
    <b-row>
      <div class="col-12">
        <div>
          <Header />
        </div>
      </div>
      <div class="col-12">
        <b-row align-v="center" id="box">
          <b-col cols="12">
            <b-form @submit="onSubmit">
              <b-card class="text-start">
                <div class="container" data-cy="forgotPwd">
                  <div class="row">
                    <div class="col-12">
                      <b-form-group id="input-group-agency" label="CPF" label-for="document">
                        <b-form-input id="document" data-cy="document" type="text" min="0" v-model="form.document"
                          :state="testaCPF(form.document)" v-mask="'###########'" required></b-form-input>
                        <b-form-invalid-feedback :state="testaCPF(form.document)">
                          Informe um documento válido.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <b-form-group id="input-group-agency" label="Código recebido via SMS" label-for="code">
                        <b-form-input id="code" data-cy="code" type="text" v-model="form.code" v-mask="'######'"
                          required></b-form-input>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <b-form-group id="input-group-agency" label="Nova Senha" label-for="password">
                        <b-form-input id="password" data-cy="password" type="password" @keyup="passwordWeight"
                          v-model="form.pwd" required></b-form-input>
                      </b-form-group>

                      <div class="row" :data-bar="wgt">
                        <div class="col-3">
                          <div class="bar-strength" data-strength="1"></div>
                        </div>
                        <div class="col-3">
                          <div class="bar-strength" data-strength="2"></div>
                        </div>
                        <div class="col-3">
                          <div class="bar-strength" data-strength="3"></div>
                        </div>
                        <div class="col-3">
                          <div class="bar-strength" data-strength="4"></div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12" v-if="wgt != 0">
                          <b-col style="text-align: right">
                            {{ wgtLabel[wgt] }}
                          </b-col>
                        </div>
                      </div>
                      <div class="row pwdRules">
                        <div class="col-12">
                          {{ wgtLabel[0].observation }}
                          <b-col>
                            {{ wgtLabel[0].condition1 }}
                          </b-col>
                          <b-col>
                            {{ wgtLabel[0].condition2 }}
                          </b-col>
                          <b-col>
                            {{ wgtLabel[0].condition3 }}
                          </b-col>
                          <b-col>
                            {{ wgtLabel[0].condition4 }}
                          </b-col>
                          <b-col>
                            {{ wgtLabel[0].condition5 }}
                          </b-col>
                        </div>
                      </div>
                    </div>

                    <div class="col-12">
                      <b-form-group id="input-group-agency" label="Confirmação de nova senha"
                        label-for="passwordConfirm">
                        <b-form-input id="passwordConfirm" data-cy="passwordConfirm" type="password"
                          :state="isSimilar()" v-model="form.pwd2" required></b-form-input>

                        <b-form-invalid-feedback :state="isSimilar()">
                          As senhas não coincidem.
                        </b-form-invalid-feedback>
                        <b-form-valid-feedback :state="isSimilar()">
                          Perfeito!
                        </b-form-valid-feedback>
                      </b-form-group>
                    </div>
                  </div>

                  <Recaptcha :sitekey="recaptchaSitekey" :callback="recaptchaCallBack" />
                  <br />

                  <div class="row">
                    <div class="col">
                      <b-button block variant="primary" class="sndbt" type="submit" :disabled="!isValid()">REDEFINIR
                        SENHA</b-button>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-form>
          </b-col>
        </b-row>
      </div>
    </b-row>
  </b-container>
</template>

<script>
import Header from "@/components/Header.vue";
import Recaptcha from "@/components/Recaptcha.vue";

export default {
  name: "Home",
  components: {
    Header,
    Recaptcha,
  },
  async created() {
    const showModal = () => {
      this.$bvModal
        .msgBoxOk(
          "Parece que o link que você está tentando acessar não está mais disponível, por favor realize novamente o procedimento.",
          {
            title: "Link inválido",
            centered: true,
          }
        )
        .then(() => window.location.replace("https://bpp.com.br"))
        .catch(() => window.location.replace("https://bpp.com.br"));
    };
    if (this.$route.params.key) {
      this.axios
        .get("/password/recovery/validate/" + this.$route.params.key)
        .then((resp) => {
          if (resp.data && resp.data.message) {
            const message = JSON.parse(resp.data.message);
            this.isLegacy = message.legacy === 'true' ? true : false;
          } else {
            this.isLegacy = false;
          }
          resp.data.status ? (this.$root.loading = false) : showModal();
        })
        .catch(showModal);
    } else {
      showModal();
    }
  },
  data() {
    return {
      recaptchaSitekey: "6LfRB3UfAAAAAA97QONUZX28OjdoXQoV1KGuuskd",
      form: {
        document: "",
        code: "",
        key: this.$route.params.key,
        pwd: "",
        pwd2: "",
        recaptcha: null,
      },
      wgt: 0,
      wgtLabel: {
        0: {
          observation: " A senha deve ter no mínimo:",
          condition1: "* 8 caracteres",
          condition2: "* uma letra maiúscula",
          condition3: "* uma letra minúscula",
          condition4: "* um número",
          condition5: "* um símbolo especial",
        },
        1: "Muito Fraca",
        2: "Fraca",
        3: "Razoável",
        4: "Senha Forte",
      },
      id: "",
      isLegacy: null,
    };
  },
  methods: {
    recaptchaCallBack(token) {
      this.form.recaptcha = token;
    },
    passwordWeight() {
      const pwd = this.form.pwd;
      const numbers = /([0-9])/;
      const alphabet = /([a-z])/;
      const alphabetUp = /([A-Z])/;
      const specialCharacters = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;
      var wgt = 0;

      if (pwd.length >= 8) {
        if (
          pwd.match(numbers) &&
          pwd.match(alphabet) &&
          pwd.match(alphabetUp) &&
          pwd.match(specialCharacters)
        ) {
          wgt = 3;
        } else if (
          (pwd.match(numbers) &&
            (pwd.match(alphabet) || pwd.match(alphabetUp))) ||
          (pwd.match(specialCharacters) &&
            (pwd.match(alphabet) || pwd.match(alphabetUp)))
        ) {
          wgt = 2;
        } else if (pwd.match(alphabet) && pwd.match(alphabetUp)) {
          wgt = 1;
        } else if (
          pwd.match(numbers) ||
          pwd.match(alphabet) ||
          pwd.match(alphabetUp) ||
          pwd.match(specialCharacters)
        ) {
          wgt = 0;
        }
      }
      this.wgt = wgt + 1;

      if (this.form.pwd === "") this.wgt = 0;
    },
    isValid() {
      const pwdok =
        this.form.pwd && this.form.pwd2 && this.form.pwd === this.form.pwd2;
      const docok = this.form.document;
      const codok = this.form.code;
      const wgtok = this.wgt > 3;
      const rctok = this.form.recaptcha != null;
      return pwdok && docok && codok && wgtok && rctok;
    },
    isSimilar() {
      if (this.form.pwd && this.form.pwd2) {
        return this.form.pwd === this.form.pwd2;
      }
    },
    testaCPF(strCPF) {
      if (strCPF) {
        let sum = 0,
          leftovers,
          i;
        if (strCPF == "00000000000") return false;

        for (i = 1; i <= 9; i++)
          sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        leftovers = (sum * 10) % 11;

        if (leftovers == 10 || leftovers == 11) leftovers = 0;
        if (leftovers != parseInt(strCPF.substring(9, 10))) return false;

        sum = 0;
        for (i = 1; i <= 10; i++)
          sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        leftovers = (sum * 10) % 11;

        if (leftovers == 10 || leftovers == 11) leftovers = 0;
        if (leftovers != parseInt(strCPF.substring(10, 11))) return false;
        return true;
      }
    },
    onSubmit(event) {
      let aux = '{"message":"Parece que o documento informado é incorreto"}';
      event.preventDefault();
      this.$root.loading = true;

      const url = this.isLegacy ? `/password/legacy/recovery/` : `/password/recovery/${this.$route.params.key}`;

      let requestBody = {};

      if (this.isLegacy) {
        requestBody = {
          password: this.form.pwd,
          password2: this.form.pwd2,
          token: this.form.code,
          document: this.form.document,
          recaptcha: this.form.recaptcha,
          id: this.$route.params.key
        }
      } else {
        requestBody = {
          password: this.form.pwd,
          password2: this.form.pwd2,
          token: this.form.code,
          document: this.form.document,
          recaptcha: this.form.recaptcha,
        }
      }

      // change passwrod request
      this.axios.post(url, requestBody)
        .then((resp) => {
          if (resp.data.status === false && resp.data.message === aux) {
            this.$root.loading = false;
            this.$bvModal.msgBoxOk(
              "Parece que o documento informado é incorreto",
              {
                title: "Dados inválidos",
                okVariant: "danger",
                noFade: true,
                hideHeaderClose: false,
                okTitle: "FECHAR",
                centered: true,
              }
            );
          } else if (resp.data.status === false && resp.data.message !== aux) {
            this.$root.loading = false;
            let message = "Por favor revise os dados e tente novamente.";
            if (resp.data.message) {
              let error = JSON.parse(resp.data.message);
              message = error.errors[0].message;
            }
            this.$bvModal.msgBoxOk(message,
              {
                title: "Dados inválidos",
                okVariant: "danger",
                noFade: true,
                hideHeaderClose: false,
                okTitle: "FECHAR",
                centered: true,
              }
            );
          } else {
            this.$root.loading = false;
            this.$bvModal
              .msgBoxOk("Senha alterada com sucesso!", {
                title: "BPP",
                centered: true,
              })
              .then(() => window.location.replace("https://bpp.com.br"))
              .catch(() => window.location.replace("https://bpp.com.br"));
          }
        });
    },
  },
  mounted() {
    this.$root.loading = false;
  },
};
</script>

<style scoped>
.home {
  font-size: 20px;
}

#box {
  margin-top: 18px;
}

.row {
  margin-bottom: 10px;
}

.action {
  width: 90%;
}

.btn-list {
  font-variant: all-small-caps;
}

.dropdown-divider {
  margin-top: 30px;
  margin-bottom: 30px;
  height: 10px;
}

.status-pwd {
  position: relative;
  top: 10px;
  font-size: 0.8em;
  color: rgb(65, 65, 65);
}

.bar-strength {
  width: 100%;
  height: 10px;
  background-color: rgb(219, 219, 219);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.sndbt {
  background-color: #0285c9;
  border-color: #0285c9;
  height: 60px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

[data-bar] {
  padding-right: 15px;
}

[data-bar] .col-3 {
  padding-right: 0px;
}

[data-bar="1"] [data-strength="1"],
[data-bar="2"] [data-strength="1"] {
  background-color: rgb(221, 88, 26);
}

[data-bar="2"] [data-strength="2"],
[data-bar="2"] [data-strength="1"] {
  background-color: rgb(204, 135, 0, 1);
}

[data-bar="3"] [data-strength="3"],
[data-bar="3"] [data-strength="1"],
[data-bar="3"] [data-strength="2"] {
  background-color: rgb(2, 133, 201, 1);
}

[data-bar="4"] [data-strength="4"],
[data-bar="4"] [data-strength="1"],
[data-bar="4"] [data-strength="2"],
[data-bar="4"] [data-strength="3"] {
  background-color: rgb(151, 233, 74);
}

@media (max-width: 576px) {
  .pwdRules {
    font-size: 0.88em;
  }
}
</style>
